import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';

const faqs = [
  {
    question: "What is Laneo?",
    answer: "Laneo is an AI-powered video creation platform that transforms your ideas into captivating videos. Simply input your concept, and our advanced AI will generate a unique video based on your description."
  },
  {
    question: "How does Laneo work?",
    answer: () => (
        <div>
          <p className="mb-4">
            Laneo leverages a sophisticated blend of AI technologies to create videos from your text prompts. It utilizes:
          </p>
          <div className="pl-4 mb-4">
            <p>
              * Large Language Models (LLMs): to interpret and expand on your text descriptions
            </p>
            <p>
              * Text-to-Speech models: to generate narration or dialogue
            </p>
            <p>
              * Computer Vision algorithms: to create and manipulate visual content
            </p>
          </div>
          <p>
            These diverse AI components work in concert, drawing from various APIs to analyze your prompt, generate relevant visuals and audio, and compile them into a coherent video sequence.
          </p>
        </div>
      )
  },
  {
    question: "What types of videos can I create with Laneo?",
    answer: "You can create a wide variety of videos, including educational content, storytelling narratives, animated shorts, and more. The possibilities are limited only by your imagination!"
  },
  {
    question: "How long does it take to generate a video?",
    answer: "The generation time for a video typically ranges from 5 to 15 minutes. This can vary slightly depending on the complexity of your prompt and the current system load. We recommend staying on the page or checking back periodically to see if your video is ready."
  },
  {
    question: "Can I edit the generated video?",
    answer: "Yes, Laneo offers some editing capabilities. After your video is generated, you can modify the prompt for each individual scene on the generated video page. This allows you to refine and customize specific parts of your video without starting over completely. For more extensive edits, you can also download your generated video and edit it using your preferred video editing software."
  },
  {
    question: "What if I'm not satisfied with the generated video?",
    answer: "If you're not happy with the result, you can try refining your prompt to be more specific or try a different approach. Remember, the more detailed and clear your prompt, the better the outcome usually is."
  },
  {
    question: "Is there a limit to how many videos I can generate?",
    answer: "There are currently no limits on the number of videos you can generate. We're offering unlimited generations for a short period of time as part of our launch promotion. However, please note that this may change in the future as we assess usage patterns and refine our service offerings."
  },
  {
    question: "Can I use the generated videos commercially?",
    answer: "Yes, you have full rights to use the videos generated by Laneo, including for commercial purposes. However, please ensure you have the necessary rights for any specific content you request in your prompts."
  },
  {
    question: "How do I share my generated videos?",
    answer: "Once your video is generated, you can download it and share it on any platform you like. We also provide direct sharing links to popular social media platforms."
  },
];

function FAQItem({ question, answer }) {
  return (
    <Disclosure as="div" className="mt-4">
      {({ open }) => (
        <>
          <DisclosureButton className="flex justify-between w-full px-4 py-3 text-lg font-medium text-left text-gray-800 bg-white rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 transition-colors duration-200 shadow-sm">
            <span>{question}</span>
            {open ? (
              <ChevronUp className="w-5 h-5 text-blue-500" />
            ) : (
              <ChevronDown className="w-5 h-5 text-blue-500" />
            )}
          </DisclosureButton>
          <DisclosurePanel className="px-4 pt-4 pb-2 text-gray-600">
            {typeof answer === 'function' ? answer() : answer}
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}

function FAQPage() {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 to-indigo-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white p-8 rounded-xl shadow-lg">
        <h1 className="text-4xl font-bold text-center mb-4 text-gray-900">Laneo</h1>
        <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Frequently Asked Questions</h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQPage;